import styled from 'styled-components'

export const TotalContainer = styled.div`
    width: 100%;
    max-width: 800px;
    background: #efefef;
    height: auto;
    margin-top: 80px;
`;

export const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    height: auto;
    min-height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
`;

export const Article_h1 = styled.h1`
    color: var(--main-dark);
    font-weight: 600;
    font-size: 3rem;
    margin-top: 0;
    @media screen and (max-width: 1000px){
        font-size: 2rem;
    }
`;

export const Article_h2 = styled.h3`
    color: var(--accent-light);
    text-transform: uppercase;
    font-family: 'roboto',monospace;
    font-weight: 300;
`;

export const Article_h3 = styled.h3`
    color: var(--accent-dark);
    font-size: 1.5rem;
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const Article_h4 = styled.h4`
    color: var(--main-dark);
    line-height: 1.5rem;
`;

export const Article_p = styled.p`
    line-height: 1.5rem;
    color: #111;
`;

export const Article_date = styled.p`
    color: var(--accent-dark);
    font-size: 1rem;
    vertical-align: middle;
    font-weight: bold;
`;

export const Article_img = styled.img`
    height: auto;
    width: auto;
    max-width: 80%;
    max-height: 60vh;
`;

export const Article_img_container = styled.span`
    
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
`;

export const SocialsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: var(--accent-light);
`;


export const SocialLink = styled.a`
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform-origin: center;
        transform: scale(1.15);
    }
    margin: 20px;
`;