import React, {useState} from 'react'
import {LandingContainer, CenterContainer, LeftContainer, RightContainer, MiddleContainer, LandingP, LandingC, LandingI, Highlight} from './LandingPageElements'
import './TypeWriter.css';
import LatestBox from '../LatestBox'
import ShowBox from '../ShowBox'
// import robot_im from '../Images/paper_isometric.gif'
var ReactRotatingText = require('react-rotating-text');

const LandingPage = ({content}) => {

    const sorted = content.sort((a,b) => b.date - a.date)
    const featured = sorted.slice(0, 1)

    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
    
    return (
        <LandingContainer>
            <CenterContainer>
                <LeftContainer>
                    <LatestBox content={featured} section = {'\u03A9'}/>
                </LeftContainer>
                <MiddleContainer>
                    
                    <LandingI><Highlight>Hi, I'm Ben</Highlight></LandingI>
                    <LandingI>Let's <ReactRotatingText style={{fontWeight: 700}} pause={1500} items={[" research", ' invent', " learn"]} eraseMode="erase" />together!</LandingI>
                    <LandingC>
                        If you're into the intersection between a curious Cambridge engineer's take on the world, and the nitty gritty FLOPS and figures from the latest conference, look no further.
                    </LandingC>
                    <LandingC>
                    I also write **free engineering notes** on a huge range of topics! Maybe I can offer a helping hand in times of bengineering need.
                    </LandingC>
                    <LandingC>
                    </LandingC>
                </MiddleContainer>
                
                <RightContainer>
                    <ShowBox/>
                </RightContainer>
                
            </CenterContainer>
        </LandingContainer>
    
    )
}

export default LandingPage



// <div>
//             <LandingContainer>
//                 <CenterContainer>
//                     {/*<Portrait src={robot_im}></Portrait>*/}
                    
//                         <LandingP><ReactRotatingText pause='1500' items={["Hi, I'm Ben!", ' The Bengineer!']} eraseMode="erase" /></LandingP>
//                         {/*<LandingC>
//                             I am an undergraduate engineering student (MEng) at the University of Cambridge with a particular focus on robotics, AI and electronics. That being said, anything which requires some interesting problem solving never fails to grab my attention!
//                         </LandingC>*/}

//                         {/*<LandingC>
//                             If you're into the intersection between a curious Cambridge engineering student's take on the world, and the nitty gritty FLOPS and figures from the latest conference, look no further ...
//                         </LandingC>

//                         <LandingC>
//                             And whilst you're here, feel free to check out my portfolio of work ...
//                         </LandingC>

//                         <LandingC>
//                             I also write *free engineering notes* on a huge range of topics! Maybe I can offer a helping hand in times of bengineering need ;)
//                         </LandingC>*/}
                    
                    
//                 </CenterContainer>
//             </LandingContainer>
//         </div>