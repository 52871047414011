import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Bar = styled.div`
    width: 100%;
    height: auto;
    min-height: 80px;
    max-height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    z-index: +5;
    overflow-y: auto;
    background: ${({filled}) => (filled ? 'var(--accent-dark)' : 'none')};
    // border-bottom: 3px solid #D9D9D9;
    @media screen and (max-width: 1100px) {
      background: ${({filled}) => (filled ? 'var(--accent-dark)' : 'var(--accent-dark)')};
    }
    
    
`;

export const ButtonContainer = styled.div`
	max-width: 1340px;
  
  margin-right: 30px;
  margin-left: 30px;
	display: flex;
	align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    flex-grow: 2;
    flex-shrink: 1;
    transition: all 0.2s ease-in-out;
    
    @media screen and (max-width: 500px){
        justify-content: center;
    }


`;

export const HomeContainer = styled.div`
	max-width: 1200px;
	display: flex;
	align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 2;
`;

export const NavBtnLink = styled(Link)`
  
  color: ${({filled}) => (filled ? '#FFFFFF' : '#000000')};
  cursor: pointer;
  margin: 20px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 20px;
  /* Second Nav */
  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${({filled}) => (filled ? '#00AED4' : '#808080')};
    @media screen and (max-width: 1100px) {
      color: #00AED4;
    }
  }
  &.active {
    
    border-bottom: 3px solid #D9D9D9;
    @media screen and (max-width: 1100px) {
      border-bottom: 3px solid #0247A4;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    margin-left: 15px;
  	margin-right: 15px;
    
  }
  @media screen and (max-width: 1100px) {
      color: #FFFFFF;
  }
`;

export const HomeBtnLink = styled(Link)`
  color: #000000;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #808080;
  }
  &.active {
    border-bottom: 3px solid #D9D9D9;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    margin-left: 15px;
  	margin-right: 15px;
  }
`;

export const ContactBtnLink = styled.a`
  
  color: ${({filled}) => (filled ? '#FFFFFF' : '#000000')};
  cursor: pointer;
  margin: 20px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid green;
  /* Second Nav */
  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${({filled}) => (filled ? '#00AED4' : '#808080')};
    @media screen and (max-width: 1100px) {
      color: #00AED4;
    }
  }
  &.active {
    
    border-bottom: 3px solid #D9D9D9;
    @media screen and (max-width: 1100px) {
      border-bottom: 3px solid #0247A4;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    margin-left: 15px;
    margin-right: 15px;
    
  }
  @media screen and (max-width: 1100px) {
      color: #FFFFFF;
  }
`;