import React, {useState} from 'react'
import {LatestContainer, LatestHeader, SectionHeader, TitleContainer} from './LatestBoxElements'
// import { CalendarBlank, TagSimple} from "phosphor-react";
import Card from '../Card'
// import background from "../Images/christs_hero.jpg"
// import {Button} from '../ButtonElement'

function formatDate(Date){
  var options = {year: "numeric", month: 'numeric', day: 'numeric'};
  return Date.toLocaleDateString([],options);
}

const LatestBox = ({content, section}) => {

    const posts = content.map(post => {

        return(
            <Card isdark key={post.title} content={post}/>
        )

    })
    
    return (

        <LatestContainer>
        <TitleContainer>
        <SectionHeader>{section}</SectionHeader>
        <LatestHeader>Latest</LatestHeader>
        </TitleContainer>
            
            {posts} 
        </LatestContainer>
            

            
        
        
        
    )
}

export default LatestBox