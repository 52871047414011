import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom';

export const CardContainer = styled.div`
	box-shadow: 0 0px 6px rgba(0,0,0, 0.4);
	width: 300px;
	height: 450px;
	background: ${({dark}) => (dark ? 'var(--accent-mid)' : '#FFFFFF')};
	cursor: pointer
	box-shadow: 0 0 50px #ccc;
    transition: 0.3s ease;
    &:hover {

        box-shadow: 0 4px 8px rgba(0,0,0, 0.4);
        transform-origin: center;
        transform: scale(1.02);

    }
`;

export const CardImageContainer = styled.div`
	width: 100%;
	height: 40%;
	background: black;
	position: relative;
	top: 0;
	left:0;
	cursor: pointer
`;

export const CardImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const CardContentContainer = styled.div`
	width: 100%;
	height: 60%;
	position: relative;
	bottom: 0;
	left:0;
	display: flex;
	justify-content: space-between;
	align-items: left;
	flex-direction: column;
	cursor: pointer
`;
 
export const CardTags = styled.h4`
	color: ${({dark}) => (dark ? '#FFFFFF' : 'var(--accent-dark)')};
	margin: 10px 10px 5px 10px;
	font-weight: 700;
	text-decoration: none;
	font-size: 0.9rem;
`;

export const CardHeader = styled.h2`
	color: ${({dark}) => (dark ? '#FFFFFF' : '#545454')};
	margin: 5px 10px 5px 10px;
	font-weight: 500;
	text-decoration: none;
`;

export const CardBody = styled.p`
	color: ${({dark}) => (dark ? '#FFFFFF' : '#545454')};
	margin: 5px 10px 5px 10px;
	font-size: 0.9rem;
	overflow: hidden;
	text-decoration: none;
`;

export const CardDate = styled.h4`
	text-decoration: none;
	color: ${({dark}) => (dark ? '#FFFFFF' : 'var(--accent-dark)')};
	margin: 5px 10px 10px 10px;
	font-weight: 700;
	font-size: 0.9rem;
	
`;

export const CardArrow = styled.p`
	color: ${({dark}) => (dark ? '#FFFFFF' : 'var(--accent-dark)')};
	position: absolute;
	right: 10px;
	bottom: 10px;
	margin: 0;
	cursor: pointer;
	text-decoration: none;
	&:hover {

        color: ${({dark}) => (dark ? 'orange' : 'orange')};
        text-decoration: none;

    }
`;