import React from 'react';
import {Bar, NavBtnLink, ButtonContainer, HomeContainer, HomeBtnLink, ContactBtnLink} from './NavbarElements';
  
const Navbar = ({fill}) => {
  return (
    <div>
      <Bar filled={fill ? 1 : 0}>
        {/*<HomeContainer>
          <HomeBtnLink to='/about'>
            Ben Zandonati
          </HomeBtnLink>
        </HomeContainer>*/}
        <ButtonContainer>
          <NavBtnLink filled={fill ? 1 : 0} exact to='/'>
            Home
          </NavBtnLink>
          <NavBtnLink filled={fill ? 1 : 0} exact to='/blog'>
            Blog
          </NavBtnLink>
          <NavBtnLink filled={fill ? 1 : 0} to='/portfolio'>
            Portfolio
          </NavBtnLink>
          <NavBtnLink filled={fill ? 1 : 0} to='/notes'>
            Notes
          </NavBtnLink>
          {/*<ContactBtnLink filled={fill ? 1 : 0} href="mailto:benzan542@gmail.com">
            Contact
          </ContactBtnLink>*/}
        </ButtonContainer>
      </Bar>
    </div>
  );
};
  
export default Navbar;