import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const LatestContainer = styled.div`
    width: 100%;
    max-width: 400px;
    height: 700px;
    background: var(--accent-light);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s ease-in-out;
    @media screen and (max-height: 750px){
        height: 100%;
    }
    @media screen and (max-width: 1100px){
        max-width: 100%;
        height: 700px;
    }

	
`;

export const LatestHeader = styled.h1`
	font-family: 'Roboto Mono', monospace;
	font-weight: 600;
	font-style: bold;
	font-size: 5rem;
	text-transform: uppercase;
	color: var(--accent-dark);
	text-align: center;
	margin: 0;
	margin-bottom: 10px;
`;

export const CloudContainer = styled.div`
	width: 100%;
	height: 400px;
	cursor: pointer;
`;

export const SocialsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 120px;
`;

export const Direction = styled.p`
	color: var(--accent-mid);
	padding: 0px;
	margin: 0px;
	justify-content:space-between;
	display: flex;
	align-items: center;
	width: 160px;

`;

export const SocialLink = styled.a`
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	&:hover {
        transform-origin: center;
        transform: scale(1.15);
    }
`;