import React, {useState, useEffect} from 'react'
import {LandingContainer, CenterContainer, MiddleContainer, CardContainer, CoverContainer, SearchContainer, SearchBar, SectionHeader} from './AllPageElements'
import LatestBox from '../LatestBox';
import Card from '../Card';
import { useParams } from 'react-router';

const AllPage = ({articles}) => {

    let { word } = useParams();
    
    const [name, setName] = useState('');
    const [foundArticles, setFoundArticles] = useState(articles);

    const filter = (e) => {
        const keyword = e.target.value;    

        if (keyword !== '') {
            const results = articles.filter((article) => {
                if (article.title.toLowerCase().includes(keyword.toLowerCase()) == true){
                    return true;
                } else if (article.tags.toLowerCase().includes(keyword.toLowerCase()) == true){
                    return true;
                } else {
                    return false;
                } 
                    // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundArticles(results);
        } else {
            setFoundArticles(articles);
            // If the text field is empty, show all users
        }

        setName(keyword);
    };

    const load_filter = (def) => {
        const keyword = def;

        if (keyword !== '') {
            const results = articles.filter((article) => {
                if (article.title.toLowerCase().includes(keyword.toLowerCase()) == true){
                    return true;
                } else if (article.tags.toLowerCase().includes(keyword.toLowerCase()) == true){
                    return true;
                } else {
                    return false;
                } 
                    // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundArticles(results);
        } else {
            setFoundArticles(articles);
            // If the text field is empty, show all users
        }

        setName(keyword);
    };

    useEffect(()=> {
        load_filter(word)
    }, [word])

    const latest_num = 27;
    const sorted = foundArticles.sort((a,b) => b.date - a.date)
    const latest = sorted.slice(0, latest_num)

    const posts = latest.map(post => {

        return(
            <CardContainer key={post.title}>
            <Card  content={post}/>
            </CardContainer>
            
        )

    })



    
    return (
    <div>
        {/*<BigBackground/>*/}
        <LandingContainer>

            <CenterContainer>
            
            <MiddleContainer>
                <SearchContainer>
                    <SectionHeader>Explore</SectionHeader>
                    <SearchBar
                        type="search"
                        value={name}
                        onChange={filter}
                        className="input"
                        placeholder="Filter"
                    />
                </SearchContainer>
                <CoverContainer>
                    {foundArticles && foundArticles.length > 0 ? (
                  posts
                ) : (
                  <span>-- More on the way! --</span>
                )}
                </CoverContainer>
                
                
            </MiddleContainer>

            </CenterContainer>

        </LandingContainer>
        
    </div>
    )
}

export default AllPage