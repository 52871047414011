import styled from 'styled-components'

export const LandingContainer = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 0;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
`;

export const CenterContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 1000px){
        flex-direction:column;
        justify-content: flex-start;
    }

    
`;

export const LeftContainer = styled.div`
    position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 3;
  top: 0;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    
    @media screen and (max-width: 1000px){
        max-width: 100%;
        height: auto;
        position: relative;
    }
`;

export const MiddleContainer = styled.div`
    // transform: translate(-50%, -50%);
    display: flex;
    align-self: flex-start;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 80px;
`;

export const CardContainer = styled.div`
    padding: 15px;
`;

export const RightContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1000px){
        max-width: 100%;
        height: auto;
    }

`;

export const CoverContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
`;

export const SearchContainer = styled.div`
    width: 100%;
    height: auto;
    background: #efefef;
    position: sticky;
    z-index: 4;
    top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const SearchBar = styled.input`
    color: var(--main-dark);
    padding: 4px 20px;
    margin: 20px;
    box-sizing: border-box;
    border: none; 
    text-decoration: none;
    outline: 3px solid transparent;
    transition: all 0.2s ease-in-out;
    &:focus {
        text-decoration: none;
        border: 3px solid #D9D9D9;
        
    }
`;

export const BigContainer = styled.p`
    position: absolute;

`;

export const SectionHeader = styled.h1`
    font-family: 'Roboto Mono', monospace;
    font-weight: 600;
    font-style: bold;
    font-size: 5rem;
    text-transform: uppercase;
    color: var(--accent-light);
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
    padding-bottom: 20px;
    width: 100%;
    background: var(--accent-dark);
    @media screen and (max-width: 1000px){
        max-width: 100%;
    }
`;