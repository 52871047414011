import React, {useState, useEffect} from 'react'
import {LandingContainer, CenterContainer, LeftContainer, MiddleContainer, CardContainer, RightContainer, CoverContainer, SearchContainer, SearchBar, BigContainer} from './PortfolioPageElements'
import LatestBox from '../LatestBox';
import Card from '../Card';
import { useHistory } from 'react-router';

const PortfolioPage = ({content, sec}) => {

    
    const sorted = content.sort((a,b) => b.date - a.date)
    const latest = sorted.slice(1, 100)
    const featured = sorted.slice(0, 1)

    const [name, setName] = useState('');
    const [foundArticles, setFoundArticles] = useState(latest);

    const filter = (e) => {
        const keyword = e.target.value;    

        if (keyword !== '') {
            const results = latest.filter((article) => {
                if (article.title.toLowerCase().includes(keyword.toLowerCase()) == true){
                    return true;
                } else if (article.tags.toLowerCase().includes(keyword.toLowerCase()) == true){
                    return true;
                } else {
                    return false;
                } 
                console.log(results)  // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundArticles(results);
        } else {
            setFoundArticles(latest);
            // If the text field is empty, show all users
        }

        setName(keyword);
    };

    const artSorted = foundArticles.sort((a,b) => b.date - a.date)

    const posts = artSorted.map(post => {

        return(
            <CardContainer>
            <Card key={post.title} content={post}/>
            </CardContainer>
            
        )

    })



    
    return (
    <div>
        {/*<BigBackground/>*/}
        <LandingContainer>
            <CoverContainer>
            </CoverContainer>
            <CenterContainer>
            <LeftContainer>
                <LatestBox  content={featured} section = {sec}/>
            </LeftContainer>
            
            <MiddleContainer>
                <SearchContainer>
                    <SearchBar
                        type="search"
                        value={name}
                        onChange={filter}
                        className="input"
                        placeholder="Filter"
                    />
                </SearchContainer>
                
                {foundArticles && foundArticles.length > 0 ? (
                  posts
                ) : (
                  <span>-- More on the way! --</span>
                )}
                
            </MiddleContainer>

            </CenterContainer>

        </LandingContainer>
        
    </div>
    )
}

export default PortfolioPage