import styled from 'styled-components'

export const LandingContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
`;

export const Portrait = styled.img`
	border-radius: 50%;
    justify-content: center;
    height: 150px;
    width: 150px;
    align-content: center;
    align-items: center;
`;

export const CenterContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1100px){
        flex-direction:column;
        justify-content: flex-start;
    }
    
`;

export const LandingP = styled.h1`
    margin-top: 24px;
    color: var(--accent-mid);
    font-size: 3rem;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    
    
    max-width: 600px;
    font-weight: 400;
    transition: 0.6s;
    @media screen and (max-width: 1600px){
        font-size: 2rem;
    }
`;

export const Highlight = styled.span`
    color: var(--accent-dark);
    font-size: 3rem;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    line-height: 3rem;
    
    max-width: 600px;
    font-weight: 400;
    transition: 0.6s;
    
`;

export const LandingC = styled.p`
    color: #000000;;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const LandingI = styled.h2`
    max-width: 250px;
    color: var(--accent-light);
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 10px;
    font-weight: 700;
    margin-bottom: 10px;
`;

export const ContentContainer = styled.div`
	flex-direction: column;
	justify-content: flex-start;
`;

export const LeftContainer = styled.div`
    height: 100%;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1100px){
        max-width: 100%;
        height: auto;
    }
`;

export const RightContainer = styled.div`
    height: 100%;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 1100px){
        max-width: 100%;
        height: auto;
    }

`;

export const MiddleContainer = styled.div`
    max-width: 400px;
    height: 200px;
    display: flex;
    // background:var(--accent-mid);
    flex-direction: column;
    justify-content:  center;
    align-items: center;
    flex-shrink: 2;
    padding: 20px;
    @media screen and (max-width: 1100px){
        max-width: 100%;
        height: auto;
    }
`;