import React, {useState} from 'react'
import {CardContainer, CardImage, CardImageContainer, CardContentContainer, CardTags, CardHeader, CardBody, CardDate, CardArrow} from './CardElements'
import { NavLink as Link } from 'react-router-dom';
// import robot_im from '../Images/test.jpeg'

function formatDate(Date){
  var options = {year: "numeric", month: 'numeric', day: 'numeric'};
  return Date.toLocaleDateString([],options);
}

const Card = ({isdark, content}) => {

    return (
        <Link style={{textDecoration: "none"}} exact to={`/article/${content.title}`}>
            <CardContainer dark={isdark}>

                <CardImageContainer >
                    <CardImage src={content.image}/>
                </CardImageContainer>
                <CardContentContainer>
                    <CardTags dark={isdark}>{content.tags}</CardTags>
                    <CardHeader dark={isdark}>{content.title}</CardHeader>
                    <CardBody dark={isdark}>{content.description}</CardBody>
                    <CardDate dark={isdark}>{formatDate(content.date)}</CardDate>
                    <CardArrow dark={isdark}>-></CardArrow>

                </CardContentContainer>
            </CardContainer>

        </Link>
        
    )
}

export default Card