import React, {useState, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'
// import remarkImages from 'remark-images'
// import rehypeRaw from 'rehype-raw'
import ReactDom from 'react-dom'
// import LazyLoad from 'react-lazyload';
import {TotalContainer, Container, Article_h1, Article_h2, Article_h3, Article_p, Article_h4, Article_date, Article_img, Article_img_container, SocialsContainer, SocialLink} from './ArticleElements'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import {useHistory,useLocation,useParams} from "react-router-dom";
import { LinkedinLogo, GithubLogo, EnvelopeSimple} from "phosphor-react";


function formatDate(Date){
  var options = {year: "numeric", month: 'numeric', day: 'numeric'};
  return Date.toLocaleDateString([],options);
}

const comp = {

	h1: ({node, ...props}) => <Article_h1 {...props} />,
	h2: ({node, ...props}) => <Article_h2 {...props} />,
	h3: ({node, ...props}) => <Article_h3 {...props} />,
	h4: ({node, ...props}) => <Article_h4 {...props} />,
	h5: ({node, ...props}) => <Article_date {...props} />,
	img: ({node, ...props}) => <Article_img_container ><Article_img {...props} /></Article_img_container>,
	p: ({node, ...props}) => <Article_p {...props} />,
	code({node, inline, className, children, ...props}) {
        const match = /language-(\w+)/.exec(className || '')
        return !inline && match ? (
          <SyntaxHighlighter
            children={String(children).replace(/\n$/, '')}
            style={a11yDark}
            language={match[1]}
            PreTag="div"
            {...props}
          />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      },
}

const Article = ({articles}) => {

	let [ content, setContent] = useState({md: ""});
	let [ articleURL, setArticleURL] = useState("");
	let [ headerInfo, setHeaderInfo] = useState(null);

	

	let { title } = useParams();
	

	// const load_filter = (def) => {
 //        const keyword = def;

 //        if (keyword !== '') {
 //            const results = articles.data.filter((article) => {
 //                if (article.title == keyword){
 //                		setArticleURL(article.source);
 //                    resolve(article.source);
 //                } else {
                		
 //                    reject();
 //                } 
 //                console.log("no article found!");    // Use the toLowerCase() method to make it case-insensitive
 //            });
            
 //        } else {
 //        		console.log("no keyword");
 //        		reject();
 //            // If the text field is empty, show all users
 //        }
 //    };


    const load_filter = (def) => {
        const keyword = def;
	        return new Promise((resolve, reject) => {
	        	if (keyword !== '') {
		            const results = articles.filter((article) => {
		                if (article.title == keyword){
		                		return true;
		                } else {
		                		return false;
		                } 
		            });
		            
		            if (results.length == 1){
		            	resolve(results[0].source);
		            	setArticleURL(results[0].source);
		            	setHeaderInfo(results[0])
		            } else {
		            	reject("error, results invalid");
		            }
		        } else {
		        		console.log("no keyword");
		        		reject('error, no title found');
		            // If the text field is empty, show all users
		        }
			  })
        
    };




	

  	useEffect(()=> {
  		load_filter(title).then((URL) => fetch(URL).then((res) => res.text()).then((md) => {setContent({ md })})).catch(err => console.log('There was an error:' + err));
  	}, [title])
    
	  console.log(articleURL)

    return (
    	<Container>
	    	<TotalContainer>
	    		{articleURL && articleURL.length > 0 ? (
	    			<div>
	    						<Article_date>{formatDate(headerInfo.date)}</Article_date>
	    						<Article_h1>{headerInfo.title}</Article_h1>
	    						<Article_h2>{headerInfo.tags}</Article_h2>
	    						<Article_h4>{headerInfo.description}</Article_h4>
	    						<hr/>
                  <ReactMarkdown components={comp} children={content.md}></ReactMarkdown>
                  <SocialsContainer>
				            <SocialLink href="https://www.linkedin.com/in/ben-zandonati-0925071a2/"><LinkedinLogo size={30} color={'var(--accent-dark)'}/></SocialLink>
				            <SocialLink href="https://github.com/BengineerZ"><GithubLogo size={30} color={'var(--accent-dark)'}/></SocialLink>
				            <SocialLink href="mailto:baz23@cam.ac.uk"><EnvelopeSimple size={30} color={'var(--accent-dark)'}/></SocialLink>
            			</SocialsContainer>
                  
            </div>
                ) : (
                  <Article_h1>-- No results found --</Article_h1>
          )}
	    	</TotalContainer>

    	</Container>
    	
	)


}

export default Article