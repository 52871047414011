import React, {useRef, useState, useMemo, useEffect} from 'react'
import * as THREE from 'three'
import { Canvas, useFrame } from '@react-three/fiber'
import { Text, TrackballControls , OrbitControls} from '@react-three/drei'
import {LatestContainer, LatestHeader, CloudContainer, SocialsContainer, Direction, SocialLink} from './ShowBoxElements'
import { LinkedinLogo, GithubLogo, CaretDown, EnvelopeSimple} from "phosphor-react";
import { useHistory } from 'react-router-dom';
import spinFont from '../Fonts/Raleway/static/Raleway-Medium.ttf';

const tags = ["Latest", 
"Conference", 
"Computer Vision", 
"Paper", 
"Maths", 
"Physics", 
"Robotics", 
"Control", 
"Planning", 
"RL", 
"DNN", 
"Unity", 
"Game Engine", 
"Auto Vehicles",
"News",
"Sigraph",
"ICLR",
"ICRA",
"Soft Robotics",
"Transformers",
"Art",
"Experiments",
"Chemistry",
"Finance",
"Cambridge",
"Coffee"
];


const ShowBox = () => {

    let history = useHistory();

    const goKeyword = (keyword) => {
        console.log(keyword);
        history.push(`/keyword/${keyword}`)
    }

    const Word = ({ children, ...props }) => {

      const color = new THREE.Color()
      const fontProps = {fontSize: 2, letterSpacing: -0.05, lineHeight: 1, 'material-toneMapped': false, font: spinFont}
      const ref = useRef()
      const [hovered, setHovered] = useState(false)
      const [active, setActive] = useState(false)
      const over = (e) => (e.stopPropagation(), setHovered(true))
      const out = () => setHovered(false)
      // const activate = (c) => {
      //   if (active){
      //       setActive(false);
      //   } else {
      //       setActive(true);
      //   }
        
        
      // }
      // Change the mouse cursor on hover
      useEffect(() => {
        if (hovered) document.body.style.cursor = 'pointer'
        return () => (document.body.style.cursor = 'auto')
      }, [hovered])
      // Tie component to the render-loop
      useFrame(({ camera }) => {
        // Make text face the camera
        ref.current.quaternion.copy(camera.quaternion)
        // Animate font color
        ref.current.material.color.lerp(color.set(hovered ? '#03367B' : '#FFFFFF'), 0.3)

      })
      return (
        // <CustomLink to='/blog' tag={Text}>{children}</CustomLink>
        <Text ref={ref} onPointerOver={over} onClick={(e) => goKeyword(children)} onPointerOut={out} {...props} {...fontProps} >{children}</Text>
      )
    }

    const Cloud = ({ count = 4, radius = 20 }) => {
      // Create a count x count random words with spherical distribution
      const words = useMemo(() => {
        const temp = []
        const spherical = new THREE.Spherical()
        const phiSpan = Math.PI / (count + 1)
        const thetaSpan = (Math.PI * 2) / count
        let k = 0;
        for (let i = 1; i < count + 1; i++){
          // Taken from https://discourse.threejs.org/t/can-i-place-obects-on-a-sphere-surface-evenly/4773/6
          for (let j = 0; j < count; j++){ 
            k++;
            temp.push([new THREE.Vector3().setFromSpherical(spherical.set(radius, phiSpan * i, thetaSpan * j)), tags[k]])
          }
        }
        return temp
      }, [count, radius])
      return words.map(([pos, word], index) => <Word key={index} position={pos} children={word} />)
    }

    
    return (

        <LatestContainer>
            <LatestHeader>Show me</LatestHeader>
            <Direction>discover <CaretDown size={24} color={'var(--accent-mid)'}/> explore</Direction>

            <CloudContainer>
                <Canvas dpr={[1, 1]} camera={{ position: [0, 0, 35], fov: 90 }}>
                  <fog attach="fog" args={['#00AED4', 0, 80]} />
                  <Cloud count={5} radius={20} />
                  {/*<TrackballControls noPan noZoom autoRotate/>*/}
                  <OrbitControls autoRotate enableZoom={false} enablePan={false} minPolarAngle={Math.PI / 2.8} maxPolarAngle={Math.PI / 2.8} />
                </Canvas>
            </CloudContainer>
            <SocialsContainer>
            <SocialLink href="https://www.linkedin.com/in/ben-zandonati-0925071a2/"><LinkedinLogo size={30} color={'var(--accent-dark)'}/></SocialLink>
            <SocialLink href="https://github.com/BengineerZ"><GithubLogo size={30} color={'var(--accent-dark)'}/></SocialLink>
            <SocialLink href="mailto:baz23@cam.ac.uk"><EnvelopeSimple size={30} color={'var(--accent-dark)'}/></SocialLink>
                
            </SocialsContainer>
            
        </LatestContainer>
            

            
        
        
        
    )
}

export default ShowBox