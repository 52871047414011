import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
// import SideMenu from './SideMenu'

import LandingPage from './LandingPage'

import Article from './Article'
import Card from './Card'
import Navbar from './Navbar'
import ShowBox from './ShowBox'
import BlogPage from './BlogPage'
import PortfolioPage from './PortfolioPage'
import NotesPage from './NotesPage'
import AllPage from './AllPage'

function processDates(array) {
  array.forEach((article) => {
    article.date = new Date(article.date)
  })

  return array
}

function App() {

  let [ articles, setArticles] = useState();

  let [ blogData, setBlogData] = useState();
  let [ portfolioData, setPortfolioData] = useState();
  let [ notesData, setNotesData] = useState();

  useEffect(()=> {
      fetch('https://benzandonatiwebsiteassets.s3.eu-west-2.amazonaws.com/articles.json')
        .then(response => response.json())
        .then((data) => {
          let processed = processDates(data);
          setBlogData(processed.filter(article => article.section == "blog"));
          setPortfolioData(processed.filter(article => article.section == "portfolio"));
          setNotesData(processed.filter(article => article.section == "notes"));
          setArticles(processed);
        });
  }, [])

  return (
    <BrowserRouter>
        <main>
        {articles && articles.length > 0 ? (
                  <Switch>
                    <Route exact path="/">
                      <Navbar/>
                      <LandingPage content={articles}/>
                      {/*<LatestBox content={blog_posts} section={'\u03A9'}/>
                      <ShowBox/>*/}
                    </Route>
                    <Route exact path="/blog">
                      <Navbar/>
                      <BlogPage content={blogData} sec="Blog"/>
                    </Route>
                    <Route exact path="/portfolio">
                      <Navbar/>
                      <PortfolioPage content={portfolioData} sec="Portfolio"/>
                    </Route>
                    <Route exact path="/notes">
                      <Navbar/>
                      <NotesPage content={notesData} sec="Notes"/>
                    </Route>
                    <Route exact path="/article/:title">
                      <Navbar fill/>
                      <Article articles={articles}/>
                    </Route>
                    <Route exact path="/keyword/:word">
                      <Navbar fill/>
                      <AllPage articles={articles}/>
                    </Route>
                </Switch>
                ) : (
                  <p>loading</p>
        )}
            
            
        </main>
    </BrowserRouter>
  );
}

export default App;
