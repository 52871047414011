import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const LatestContainer = styled.div`
    width: 100%;
    max-width: 400px;
    height: 700px;
    background: var(--accent-dark);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s ease-in-out;
    
    @media screen and (max-height: 750px){
        height: 100vh;
    }
    @media screen and (max-width: 1100px){
        max-width: 100%;
        margin-top: 40px;
        height: 700px;
    }

`;

export const LatestHeader = styled.h1`
	font-family: 'Roboto Mono', monospace;
	font-weight: 600;
	font-style: bold;
	font-size: 5rem;
	text-transform: uppercase;
	color: var(--accent-light);
	text-align: center;
	margin: 0;
	margin-bottom: 20px;
	padding: 0;
	@media screen and (max-width: 1100px){
        max-width: 100%;
    }
    
`;

export const SectionHeader = styled.h2`
	font-weight: bold;
	font-size: 1.5rem;
	text-transform: uppercase;
	color: #FFFFFF;
	text-align: left;
	margin: 0;
	
	padding: 0;
	align-self: flex-start;
	@media screen and (max-width: 650px){
        display: none;
    }
`;

export const TitleContainer = styled.div`
	margin-top: 20px;
	width: 100%;
    max-width: 300px;
`;