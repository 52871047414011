import styled from 'styled-components'

export const LandingContainer = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 0;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
`;

export const CenterContainer = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 1100px){
        flex-direction:column;
        justify-content: flex-start;
    }

    
`;

export const LeftContainer = styled.div`
    position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 3;
  top: 0;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    
    @media screen and (max-width: 1100px){
        max-width: 100%;
        height: auto;
        position: relative;
    }
`;

export const MiddleContainer = styled.div`
    // transform: translate(-50%, -50%);
    display: flex;
    align-self: flex-start;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 80px;
    @media screen and (max-width: 1100px){
        width: 100%;
        height: auto;
        padding-top: 10px;
    }
`;

export const CardContainer = styled.div`
    padding: 15px;
`;

export const RightContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1100px){
        max-width: 100%;
        height: auto;
    }

`;

export const CoverContainer = styled.div`
    position: fixed;
    right:0;
    top: 0;
    width: 100%;
    height: 80px;
    background: #efefef;
    z-index: 1;
`;

export const SearchContainer = styled.div`
    width: 100%;
    height: 50px;
    background: #efefef;
    position: sticky;
    z-index: 4;
    top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1100px){
        top:20px;
    }
`;

export const SearchBar = styled.input`
    color: var(--main-dark);
    padding: 4px 20px;
    box-sizing: border-box;
    border: none; 
    text-decoration: none;
    outline: 3px solid transparent;
    transition: all 0.2s ease-in-out;
    &:focus {
        text-decoration: none;
        border: 3px solid #D9D9D9;
        
    }
`;

export const BigContainer = styled.p`
    height: 400px;
    z-index: -1;
    width: 400px;
    position: absolute;
    overflow: hidden;
    top: 120px;
    right: 0;
    //transform: rotate(-90deg);
    word-wrap: break-word;
    align-self: flex-start;
    align-items: center;
    text-align: center;
    line-height: 16rem;
    font-family: 'Roboto Mono', monospace;
    font-weight: 600;
    font-style: bold;
    font-size: 100px;
    text-transform: uppercase;
`;